<template>
    <div class="email">
    <Header></Header>
    <div class="email-banner">
      <div class="banner-img-font">
      <p>
        <strong>电子邮箱验证</strong>
        <ul>
          <li>验证电子邮箱以后，方便您更全面的接收平台的消息通知，</li>
          <li>或者您的任务进度需求。</li>
        </ul>
      </p>
      </div>
    </div>
    <div class="email-border">
       <div class="email-border-center" >
        <img src="@/icons/svg/audit/成功120X120.svg" alt=""><br>
        <span>您的邮箱地址已经验证通过</span>
        <button class="f-18" @click="$redirectUrl.jumpToFrom('/home/page')">返回主页</button>
       </div>
       <div class="email-border-center" v-show="false">
        <img src="@/icons/svg/audit/失败120X120.svg" alt=""><br>
        <span>抱歉，您的邮箱验证失败，请重试</span>
        <button class="f-18" type="button" @click="$redirectUrl.jumpToFrom('/home/page')">返回上一步</button>
       </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.email {
  background-color: #f8f8f8;
}
.banner-img-font {
  background-image: url(~@/assets/banner/底图.jpg);
  width: 100%;
  height: 400px;
  background-size: cover;
  p {
    position: relative;
    width: 800px;
    margin: 0 auto;
    top: 30px;
    color: #fff;
    strong {
      font-size: 18px;
      display: block;
      margin-bottom: 23px;
    }
    li {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}
.email-border {
  width: 800px;
  height: 434px;
  background: #ffffff;
  margin: 0 auto;
  margin-top: -250px;
  .email-border-center{
    height: 240px;
    width: 270px;
    margin: 0 auto;
    position: relative;
    top: 100px;
    text-align: center;
  }
}
img{
  width: 120px;
  height: 120px;
}
span{
  font-size: 18px;
  color: #575757;
  display: block;
  margin-top: 20px;
  margin-bottom: 40px;
}
button{
 width: 200px;
 height: 36px;
  background-color: #00a2e6;
}
</style>